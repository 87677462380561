<template>
  <b-alert
    :variant="message ? message.variant : 'danger'"
    dismissible
    fade
    :show="!!message && !!message.message && message.message != ''"
    @dismissed="hideMessage"
  >
    {{ message ? message.message : "" }}
  </b-alert>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters({
      message: "GET_MESSAGE",
    }),
  },
  methods: {
    ...mapActions({ hideMessage: "DELETE_MESSAGE" }),
  },
};
</script>
