<template>
  <div>
    <Alert />
    <b-card class="h-100" no-body>
      <b-card-header>
        <b-row class="mb-3">
          <b-col>
            <h3 class="float-left">{{ $t("views.parameters.title") }}</h3>
            <back-button class="float-right" />
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <b-form-checkbox v-model="enabled" name="check-button" switch>{{
              $t("views.parameters.showEnabledParameters")
            }}</b-form-checkbox>
          </b-col>
          <b-col>
            <b-button-toolbar>
              <b-button-group class="ml-auto">
                <b-input
                  v-model="keyword"
                  size="sm"
                  :placeholder="$t('general.search')"
                />
                <b-button
                  v-if="keyword == ''"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('general.search')"
                >
                  <b-icon-search />
                </b-button>
                <b-button
                  v-else
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('general.search')"
                  @click="keyword = ''"
                >
                  <b-icon-x />
                </b-button>
              </b-button-group>
              <b-button-group class="mx-1">
                <b-button
                  v-show="enabled"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.parameters.addParameter')"
                  @click="showAdd = !showAdd"
                >
                  <b-icon-plus />
                </b-button>
                <b-button
                  v-show="enabled"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.parameters.editParameter')"
                  :disabled="isSelected"
                  @click="Edit"
                >
                  <b-icon-pencil />
                </b-button>
                <b-button
                  v-show="enabled"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.parameters.deleteParameter')"
                  :disabled="isSelected"
                  @click="removeParameter"
                >
                  <b-icon-trash />
                </b-button>
                <b-button
                  v-show="!enabled"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.parameters.Activate')"
                  :disabled="isSelected"
                  @click="activateParameter"
                >
                  <b-icon-check />
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </b-col>
        </b-row>
      </b-card-header>
      <AddParameter
        class="p-3"
        v-bind:show.sync="showAdd"
        :idOrganizationalUnit="idOrganizationalUnit"
      />
      <b-card-body class="overflow-auto p-0">
        <b-table
          :fields="fields"
          :items="items"
          outlined
          small
          :busy="busy"
          :selectable="selectable"
          :filter="keyword"
          :filter-ignored-fields="['idParameter', 'enabled']"
          select-mode="single"
          show-empty
          :emptyText="$t('placeholders.NoInformationAdded')"
          @row-selected="onRowSelected"
        >
          <template v-slot:table-busy>
            <div class="text-center text-primary my-2">
              <simple-loader />
            </div>
          </template>
          <template v-slot:cell(parameterName)="data">
            <div v-if="!data.item.Edit">
              {{ data.value }}
              <b-tooltip target="disabled-wrapper">{{
                $t("views.ou.deafult")
              }}</b-tooltip>
            </div>
            <div v-else>
              <b-input
                v-model="data.item.parameterName"
                block
                size="sm"
                :placeholder="$t('views.ou.EnterName')"
              />
            </div>
          </template>
          <template v-slot:cell(enabled)="data">
            <div v-if="!data.item.Edit">
              <div v-if="data.value" class="text-success">
                <strong>
                  <b-icon-check />
                </strong>
              </div>
              <div v-else class="text-danger">
                <strong>
                  <b-icon-x />
                </strong>
              </div>
            </div>
            <b-row v-else>
              <b-col>
                <b-button
                  variant="success"
                  block
                  size="sm"
                  v-on:click="updateParameter"
                  >{{ $t("general.save") }}</b-button
                >
              </b-col>
              <b-col>
                <b-button
                  variant="danger"
                  block
                  size="sm"
                  v-on:click="cancelUpdate()"
                  >{{ $t("views.ou.Cancel") }}</b-button
                >
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddParameter from "@/components/OrganizationalUnit/AddParameter";
import Alert from "@/components/general/alert";

export default {
  components: {
    AddParameter,
    Alert,
  },
  data() {
    return {
      busy: true,
      idOrganizationalUnit: 0,
      enabled: true,
      showAdd: false,
      selected: [],
      currentItem: null,
      selectable: true,
      keyword: "",
    };
  },
  watch: {
    enabled() {
      this.loadParameters();
    },
  },
  computed: {
    ...mapGetters("Parameters", ["parameters"]),
    fields() {
      return [
        {
          key: "idParameter",
          sortable: true,
          label: this.$t("fields.ParameterPanel.Id"),
        },
        {
          key: "parameterName",
          sortable: true,
          label: this.$t("fields.ParameterPanel.Name"),
        },
        {
          key: "enabled",
          sortable: false,
          label: this.$t("fields.ParameterPanel.Enabled"),
        },
      ];
    },
    isSelected() {
      return this.selected.length < 1;
    },
    items() {
      return this.parameters;
    },
  },
  methods: {
    ...mapActions("Parameters", {
      postParameter: "addParameter",
      getParameters: "getParameters",
      deleteParameter: "deleteParameter",
      putParameter: "putParameter",
      enableParameter: "enableParameter",
    }),
    onRowSelected(items) {
      this.selected = items;
      this.currentItem = this.selected[0];
    },
    addParameter() {
      this.busy = true;
      this.postParameter({
        api: this.$api,
        idCaseTemplate: this.idCaseTemplate,
        idParameter: this.form.idParameter,
        value: this.form.value,
      }).finally(() => {
        this.busy = false;
      });
    },
    removeParameter() {
      this.busy = true;
      this.deleteParameter({
        api: this.$api,
        idParameter: this.currentItem.idParameter,
      }).finally(() => {
        this.busy = false;
      });
    },
    activateParameter() {
      this.busy = true;
      this.enableParameter({
        api: this.$api,
        idParameter: this.currentItem.idParameter,
      }).finally(() => {
        this.busy = false;
      });
    },
    updateParameter() {
      this.busy = true;
      this.putParameter({
        api: this.$api,
        idParameter: this.currentItem.idParameter,
        parameterName: this.currentItem.parameterName,
      })
        .then(() => {
          this.cancelUpdate();
        })
        .finally(() => {
          this.busy = false;
        });
    },
    cancelUpdate() {
      this.selected[0].Edit = false;
      this.selectable = true;
    },
    loadParameters() {
      this.busy = true;
      this.getParameters({
        api: this.$api,
        idOrganizationalUnit: this.idOrganizationalUnit,
        enabled: this.enabled,
      }).finally(() => {
        this.busy = false;
      });
    },
    Edit() {
      this.selected[0].Edit = true;
      this.selectable = false;
    },
  },
  created() {
    const { idOrganizationalUnit } = this.$route.params;
    this.idOrganizationalUnit = parseInt(idOrganizationalUnit);
    this.loadParameters();
  },
};
</script>
