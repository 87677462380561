<template>
  <b-form class="my-3" @submit="onSubmit" @reset="onReset" v-if="show">
    <b-row v-if="busy">
      <b-col class="text-center">
        <simple-loader />
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col xs="12" sm="8" md="9" lg="9" xl="9">
        <label class="sr-only">{{ $t("general.name") }}</label>
        <b-input
          v-model="Name"
          :placeholder="$t('views.user.EnterName')"
          cols="12"
          size="sm"
        ></b-input>
      </b-col>
      <b-col>
        <b-button variant="success" type="submit" block size="sm">{{
          $t("general.save")
        }}</b-button>
      </b-col>
      <b-col>
        <b-button variant="danger" type="reset" block size="sm">{{
          $t("general.cancel")
        }}</b-button>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      require: true,
    },
    idOrganizationalUnit: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      Name: "",
      busy: false,
    };
  },
  methods: {
    ...mapActions("Parameters", ["postParameter"]),
    onSubmit(oEvent) {
      oEvent.preventDefault();
      const that = this;
      that.busy = true;
      that
        .postParameter({
          api: that.$api,
          parameterName: that.Name,
          idOrganizationalUnit: that.idOrganizationalUnit,
        })
        .then(() => {
          that.resetForm();
        })
        .catch(() => {
          that.resetForm();
        });
    },
    onReset(oEvent) {
      oEvent.preventDefault();
      this.resetForm();
    },
    resetForm() {
      this.busy = false;
      this.$emit("update:show", false);
      this.Name = "";
    },
  },
};
</script>

<style></style>
